<template>
    <div>
        <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                :immediate-check="false"
                @load="onLoad"
                :offset="200"
            >
            <div class="content-infp" v-for="item in list" :key="item">
                <p v-if="status ==17">
                    <span>进场时间：{{item.enter_factory_time}}</span>
                </p>
                <p v-if="status ==17">
                    <span>离场时间：{{item.leaving_time}}</span>
                </p>
                <p>
                    <span>拖车时间：{{item.appointment_tuo_time}}</span>
                </p>
                <p  v-if="status ==10">
                    <span>拖车地点：{{item.appointment_locationDetail}}</span>
                </p>
                <p>
                    <span>车牌号码：{{item.carNumberPlate}}</span>
                </p>
                <p>
                    <span>车架号码：{{item.vin}}</span>
                </p>
                <div v-if="status ==10 " class="btn-anniu">
                    <van-button v-if="item.transport_status == 1" type="info" @click="InTheAuditChange(item,1)" >进场审核</van-button>
                    <van-button v-if="item.transport_status == 2" type="info" @click="kongche(item)" >空车驶回</van-button>
                </div>
                <div v-if="status ==15 " class="btn-anniu">
                    <van-button type="info" @click="InTheAuditChange(item,2)" >查看详情</van-button>
                </div>
                <div v-if="status ==13 " class="btn-anniu">
                    <van-button type="info" @click="TheAuditChange(item,2)" >查看详情</van-button>
                </div>
            </div>
            </van-list>
        </van-pull-refresh>
        <van-popup
            v-model="kongtuoshow"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{height: '100%',}"
        >
        <NoConsignment @CarCloes="CarCloes" ref="kongtuo" @CarCloeskong="CarCloeskong" />
    </van-popup>

    </div>
</template>

<script>
import {checkList} from '@/api/audit'
import {Toast} from 'vant'
import NoConsignment from '@/components/NoConsignment'
    export default {
        props:['status'],
        components: {
            NoConsignment,
        },
        data() {
            return {
                refreshing: false,//上啦的
                loading: false,//下拉等待圈圈
                kongtuoshow: false,//下拉等待圈圈
                finished: true,//下拉的状态 
                list:[],
                page:0,
                show:false,//弹窗
            }
        },
        methods: {
            kongche(item){
                this.kongtuoshow = true
                this.$nextTick(()=>{
                    this.$refs.kongtuo.set(item,'ShenT')
                    this.$refs.kongtuo.kong()
                })
            },
            InTheAuditChange(item,num){
                this.$router.push({path:'/InTheAudit',query:{id:item.id,ids:num,url:'acdit'}})
            },
            TheAuditChange(item,num){
                this.kongtuoshow = true
                this.$nextTick(()=>{
                    this.$refs.kongtuo.set(item,'ShenK')
                })
                
            },
            //关闭弹窗
            CarCloes(){
                this.show = false
                this.kongtuoshow = false
            },
            CarCloeskong(){
                console.log(123)
                this.kongtuoshow = false
                // this.lodData()
                this.$emit('changeActive',3)
            },
            async towage(i){
                this.show = true
                this.$nextTick(()=>{
                    this.$refs.InfoRef.getInfo(i)
                })
                    // const {data} = await ExecuteConsignment({id:i.id,type:num}).then(res=>res)
                    // console.log(data)
               
            },
            // 联系电话
            openPhone(val){
                window.location.href = 'tel://' + val.phone
            },
            //采集信息
            openGather(val,num){
                this.$router.push({path:'/InfoGather',query:{idx:num,id:val.id}})
            },
              //加载 上拉
              onRefresh() {
                this.page = 0
                        // 清空列表数据
                this.list = []
                this.finished = false;
                this.refreshing = false
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.loading = false;
                // this.onLoad();
            },
            //加载
             onLoad(){
                let this_ = this
                let toast = Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中..."
                });
                setTimeout(async()=>{
                    try{
                        let page = this_.page + 1
                        this_.page = page
                    let obj = {
                        status:this.status,
                        page
                    }
                    const {data} = await checkList(obj).then(res=>res)
                    toast.clear();
                    if(data.data.data.length==0){
                            //this_.list = [] 
                            this_.finished = true;
                            this_.refreshing = false
                        }else{
                            let arr = data.data.data
                            arr.forEach(element => {
                                this_.list.push(element)
                            });
                            if(this_.list.length >= data.data.total){
                                this_.finished = true;
                                this_.refreshing = false
                            }else{
                                this_.finished = false;
                                // this_.refreshing = false
                            }
                        }
                        this_.loading = false;
                    }
                    catch{
                        this_.finished = true;
                        this_.refreshing = false
                        toast.clear();
                        this_.$toast.fail('请求出错！')
                    }
                    
                },1000)
            },
            lodData() {
                // console.log(this.status)
                this.page=0
                this.list = [] 
                this.onLoad()
            }
        },
    }
</script>

<style lang="scss" scoped>
.van-list{
    min-height: 40vh;
}
.content-infp{
    margin-top: 10px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #eee;
    .btn-anniu{
        text-align: center;
        .van-button{
            width: 150px;
        }
    }
}
</style>