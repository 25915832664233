<template>
    <div>
        <Head :title="'车辆列表'" :out="'SecurityManagement'" />
        <div class="warper-box">
            <van-tabs  v-model="active" @click="ChangeHan($event)" >
                <van-tab title="待进场审核"> <AuditPage ref="AuditRef0" status="10" @changeActive="changeActive" />  </van-tab>
                <van-tab title="已进场保全"> <AuditPage ref="AuditRef1" status="15" />   </van-tab>
                <van-tab title="已离场"> <AuditPage ref="AuditRef2" status="17" />   </van-tab>
                <van-tab title="空车驶回"> <AuditPage ref="AuditRef3" status="13" @changeActive="changeActive" />   </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
import Head from '@/components/Head'
import AuditPage from './AuditPage'
    export default {
        components: {
            Head,AuditPage
        },
        data() {
            return {
                active: 0
            }
        },
        mounted () {
            if(this.$route.query.active==0){
                this.active = 0
            }else if(this.$route.query.active==1){
                this.active = 1
            }else if(this.$route.query.active==2){
                this.active = 2
            }else if(this.$route.query.active==3){
                this.active = 3
            }
            this.ChangeHan()
        },
        methods: {
            changeActive(n){
                this.active = n
                this.ChangeHan()
            },
            ChangeHan() {
                if(this.active==0){
                    this.$nextTick(()=>{
                        this.$refs.AuditRef0.lodData()
                    })
                }else if(this.active==1){
                    this.$nextTick(()=>{
                        this.$refs.AuditRef1.lodData()
                    })
                }else if(this.active==2){
                    this.$nextTick(()=>{
                        this.$refs.AuditRef2.lodData()
                    })
                }else if(this.active==3){
             
                    this.$nextTick(()=>{
                        this.$refs.AuditRef3.lodData()
                    })

                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.warper-box{
    margin-top: 50px;
    padding: 5px;
}

</style>